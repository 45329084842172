import pricing from '@glass/rock/modules/payments/pricings/configs/assignments.config';

import cancel from '@glass/common/modules/assignments/configs/cancel';
import cheetahSelection from '@glass/common/modules/assignments/configs/cheetahSelection';
import currencySelector from '@glass/common/modules/assignments/configs/currencySelector';
import disableBuilderQueryCheck from '@glass/common/modules/assignments/configs/disableBuilderQueryCheck';
import qaOrigin from '@glass/common/modules/assignments/configs/qaOrigin';
import resumeDownloadUrlVersion from '@glass/common/modules/assignments/configs/resumeDownloadUrlVersion';
import retargetEmailFrequencyInterval from '@glass/common/modules/assignments/configs/retargetEmailFrequencyInterval';
import retargetEmailProvider from '@glass/common/modules/assignments/configs/retargetEmailProvider';
import retargetEmailSendTimeOptimization from '@glass/common/modules/assignments/configs/retargetEmailSendTimeOptimization';
import taxonomyClient from '@glass/common/modules/assignments/configs/taxonomyClient';
import usdOnly from '@glass/common/modules/assignments/configs/usdOnly';
import createAssignmentConfigs from '@glass/common/modules/assignments/createAssignmentConfigs';
import { AssignmentSetting } from '@glass/common/modules/assignments/types';

export const userAssignments: AssignmentSetting[] = [
  cancel,
  cheetahSelection,
  resumeDownloadUrlVersion,
  qaOrigin,
  disableBuilderQueryCheck,
  taxonomyClient,
  ...pricing,
  retargetEmailProvider,
  retargetEmailFrequencyInterval,
  retargetEmailSendTimeOptimization,
  currencySelector,
  usdOnly,
];

const configs = createAssignmentConfigs(userAssignments);

export default configs;
